import React, {useState, useEffect} from "react";
import {lotInfo} from "../shared/interfaces/LotModels.interface";
import {createStyles, Grid, makeStyles, Paper, Theme, Typography} from "@material-ui/core";

type LotInfoTileProps = {
    lotInfo: lotInfo
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            fontFamily: "Times New Roman"
        },
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            maxWidth: 500,
            textAlign: "left"
        },
        image: {
            width: 128,
            height: 128,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
    }))

const LotInfoTile = (props: LotInfoTileProps) => {
    let [lotInfo, setLotInfo] = useState(props.lotInfo)
    const classes = useStyles();

    useEffect(() => {
        setLotInfo(props.lotInfo)
    }, [props.lotInfo])


    return (
        <div className={classes.root}>
            <Paper className={classes.paper} color="primary">
                <Grid container spacing={1} >
                    <Grid container direction="column" item md={3}>
                        <Grid item>
                            <Typography variant={"body1"}>
                                Lot Size:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"}>
                                Lot Price:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"}>
                                Description
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" item md={9}>
                        <Grid item>
                            <Typography variant={"body1"}>
                                {lotInfo ? lotInfo.lotSize : null}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"}>
                                $$$$$
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"}>
                                What else is interesting about this lot?
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default LotInfoTile;