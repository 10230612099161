import React, {useEffect, useState} from 'react';
import Drawer from '@material-ui/core/Drawer';
import LotOptionsMenu from "./lotOptions";
import {Grid, Button, Typography, makeStyles, createStyles} from "@material-ui/core";
import LotInfoTile from "./lotInfoTile";
import CloseIcon from "@material-ui/icons/Close";
import LotViewer from "./lotViewer";


const drawerWidth = 480;

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        }
    }))

type DrawerProps = {
    lotNum: number
    onClick: (modelKey) => void;
    project_name: string
    lotElement: HTMLElement | undefined
}

const PersistentDrawer = (props: DrawerProps) => {
    let [loading, setLoading] = useState(true);
    let [lotModelData, setLotModelData] = useState({lotInfo: {}, models: []});
    let [open, setOpen] = useState(false);
    let [lotNum, setLotNum] = useState(props.lotNum);

    const classes = useStyles();

    useEffect(() => {
        if (loading) {
            fetch('data.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(response => response.json())
                .then(respJson => {
                    setLoading(false);
                    setLotModelData(respJson);
                });
        }
        console.log(lotNum)
        setLotNum(props.lotNum)
        if (lotNum > 0) {
            handleDrawerOpen()
        } else {
            handleDrawerClose()
        }

    }, [loading, props.lotNum, lotNum])

    const handleDrawerOpen = () => {
        console.log("Ran setOpen")
        setOpen(true);
    };

    const handleDrawerClose = () => {
        console.log("Ran setClose")
        setOpen(false);
    };


    if (loading) {
        return (
            <Typography variant={"h2"}>
                LOADING
            </Typography>
        )
    } else {
        return (
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                color="primary"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div>
                    <Grid container spacing={1} >
                        <Grid container>
                            <Grid item xs={3}>
                                <Button color="primary"
                                        startIcon={<CloseIcon/>} onClick={handleDrawerClose}/>
                            </Grid>
                            <Grid item lg={12}>
                                <LotViewer
                                    lotElement={props.lotElement}
                                    lotNum={props.lotNum}
                                    lotDimensions={""}/>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"}>
                            <LotInfoTile lotInfo={lotModelData.lotInfo[props.lotNum.toString()]}/>
                            <LotOptionsMenu lotInfo={lotModelData.lotInfo}
                                            models={lotModelData.models}
                                            lotNum={props.lotNum}
                                            onClick={props.onClick}
                                            project_name={props.project_name}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
        )
    }
}

export default PersistentDrawer;
