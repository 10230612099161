import { createMuiTheme } from '@material-ui/core/styles';
import {responsiveFontSizes} from "@material-ui/core";

let theme = createMuiTheme({
    palette: {
        primary: {
            main: "#202f39"
        },
        secondary: {
            main: "#dcb754"
        }
    },
    typography: {
        fontFamily: [
            'Cinzel-Regular',
            'Times New Roman',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h2: {
            fontSize: '4rem',
            '@media (min-width:600px)': {
                fontSize: '4rem',
            },
        },
        body1: {
            fontFamily:[
                'Times New Roman',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(',')
        }
    },
});
theme = responsiveFontSizes(theme);

export default theme;