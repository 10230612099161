import React, {Component} from 'react';
import './App.css';
import SitePlan from "./components/siteplan";
import PersistentDrawer from "./components/drawer";
import BlackLineModal from "./components/blackLineModal";
import {ThemeProvider} from "@material-ui/core";
import theme from "./shared/customThemeProvider"

type AppState = {
    drawerOpen: boolean
    loading: boolean
    drawerLotNumTarget: number
    modelOptionSelected: boolean
    modelOptionKey: string
    projectName: string
    lotElement: HTMLElement | undefined
}


// const ProjectDetails = React.createContext("projectDetails"){"projectKey": "lions-park", "projectDisplayName": "Lions Park"}


class App extends Component<{}, AppState> {
    constructor(props) {
        super(props);
        this.updateDrawerLotTarget = this.updateDrawerLotTarget.bind(this)
        this.updateCurrentModelSelection = this.updateCurrentModelSelection.bind(this)
        this.closeBlackLineOverlay = this.closeBlackLineOverlay.bind(this)
        this.state = {
            drawerOpen: false,
            loading: true,
            drawerLotNumTarget: -1,
            modelOptionSelected: false,
            modelOptionKey: "",
            projectName: "lions-park",
            lotElement: undefined
        }
    }

    updateDrawerLotTarget(event) {
        const currentLotNum = event.target.dataset.lotNum
        // TODO onclick anywhere else on the screen, hide the drawer
        // if you click on a different unit, update the unit in the drawer
        if (this.state.drawerOpen) {
            //if the target of the click event is not the previous target
            this.setState({...this.state, drawerLotNumTarget: currentLotNum, lotElement: event.target})
        } else {
            this.setState({...this.state, drawerOpen: true, drawerLotNumTarget: currentLotNum, lotElement: event.target});
        }
    };

    updateCurrentModelSelection(modelKey: string) {
        // Accepts the modelKey for the model that is clicked on in the drawer, then passes that to the black line Modal
        // FIXME This POS passes data up and down the tree to go between the drawer and the svg. There has to be
        //  a better way to do this.
        this.setState({...this.state, modelOptionSelected: true, modelOptionKey: modelKey});
    }


    closeBlackLineOverlay() {
        // Used to close the modal for black lines
        this.setState({...this.state, modelOptionSelected: false})
    }


    render() {
        return (
            <ThemeProvider theme={theme}>
                <div className="App">
                    <PersistentDrawer
                        lotNum={this.state.drawerLotNumTarget}
                        onClick={this.updateCurrentModelSelection}
                        project_name={this.state.projectName}
                        lotElement={this.state.lotElement}
                    />
                    <SitePlan
                        drawerIsOpen={this.state.drawerOpen}
                        updateDrawerLotTarget={this.updateDrawerLotTarget}/>
                    <BlackLineModal open={this.state.modelOptionSelected}
                                    optionKey={this.state.modelOptionKey}
                                    project_name={this.state.projectName}
                                    closeFunction={this.closeBlackLineOverlay}/>
                </div>
            </ThemeProvider>

        )
    }

}

export default App;
