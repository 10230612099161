import React from 'react';
import {useState, useEffect} from 'react';
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import {Button, createStyles, makeStyles, Theme} from "@material-ui/core";
import {ReactComponent as SitePlanSvg} from '../assets/siteplan.svg'

const drawerWidth = 480

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        SVG: {
            paddingBottom: "75%",
            width: "100%"
        },
        SVGWrapper: {
            padding: 8,
            resize: "horizontal",
            maxWidth: "100%"
        }
    }),
);


type SitePlanProps = {
    updateDrawerLotTarget: (e) => void;
    drawerIsOpen: boolean
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function toggleDrawerHandler(event, f) {
    console.log(event)
    if (event.target.classList.contains("lot")) {
        f(event)
        // zoomToElement(event.target)
        // console.log("Zooming to " + event.target.id)
    }
}

function addClickListeners(updateDrawer) {
    let lots = document.getElementsByClassName("lot")
    for (let i = 0; i < lots.length; i++) {
        lots[i].addEventListener("click", (e) => toggleDrawerHandler(e, updateDrawer))
    }
}

function SitePlanSVGBody(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (loading) {
            addClickListeners(props.updateDrawerLotTarget)
            setLoading(false)
        }

    }, [loading, setLoading, props])
    return (
        <div>
            <SitePlanSvg className={classes.SVG} style={{height: props.height}}/>
        </div>
    )
}


function SitePlan(props: SitePlanProps) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    useEffect(() => {


        function handleResize() {
            console.log("resize")
            setWindowDimensions(getWindowDimensions());
        }


        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [props]);

    return (
            <TransformWrapper initialScale={1}
                              limitToBounds={false}
                              centerOnInit={true}
                              minScale={0.5}
            >
                {({zoomToElement, resetTransform}) => (
                    <>
                        <TransformComponent>
                            <Button onClick={()=>zoomToElement("Lot_2")}/>
                            <div style={{width: windowDimensions.width, height: windowDimensions.height}}>
                                <SitePlanSVGBody
                                    height={windowDimensions.height}
                                    updateDrawerLotTarget={props.updateDrawerLotTarget}
                                    zoomToElement={zoomToElement}

                                />
                            </div>
                        </TransformComponent>
                    </>
                )}
            </TransformWrapper>
    )
}


export default SitePlan;