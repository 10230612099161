import React from "react";
import {Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import type {modelInfo, lotsInfoObject, lotInfo} from '../shared/interfaces/LotModels.interface'


function filterLotOptions(lotInfo: lotInfo, models: modelInfo[]) {
    // When no selection is made, the drawer's default is -1
    if (lotInfo === undefined) {
        return []
    }
    return models.filter((item) => {
            return lotInfo.modelOptions.includes(
                models.indexOf(item)
            )
        }
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        fontFamily: "Times New Roman"
    },
    LotOption: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    LotOptionImg: {
        "max-height": '480px',
        "max-width": '180px'
    }
}));

type LotOptionsMenuProps = {
    models: modelInfo[]
    lotInfo: lotsInfoObject
    lotNum: number
    onClick: (modelKey) => void;
    project_name: string
}

const LotOptionsMenu = (props: LotOptionsMenuProps) => {
    // This should return a small box with the image and name of unit available
    // TODO make this an ID

    if (props.lotNum < 0) {
        return (
            <div/>
        )
    }
    const filteredLotOptions = filterLotOptions(props.lotInfo[props.lotNum.toString()], props.models)

    let lotOptionArray = filteredLotOptions.map((option: modelInfo) =>
        // onClick, return the key of the clicked Model option to <App/> so that the modal can be displayed.
        <Grid item xs key={option.key} onClick={() => props.onClick(option.key)}>
            <LotOption model={option}
                       project_name={props.project_name}
            />
        </Grid>
    )
    if (lotOptionArray.length === 0 ) {
        lotOptionArray = [
            <Grid item xs>
                <Typography variant="h3">
                    No model options available
                </Typography>
            </Grid>
        ]
    }

    return (
        <div className="LotOptionMenu">

            <Grid container spacing={3} direction="column">
                {lotOptionArray}
            </Grid>
        </div>
    )
}

type LotOptionProps = {
    model: modelInfo
    project_name: string
}

const LotOption = (props: LotOptionProps) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={1}>

                    <Grid item>
                        <img className={classes.LotOptionImg}
                             src={"https://s3.us-west-1.amazonaws.com/assets.siteplan.xyz/"+ props.project_name + '/model-images/' + props.model.img_name}
                             alt={props.model.title}/>
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={1}>
                            <Grid item xs>
                                <Typography variant={"h6"}>
                                    {props.model.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1">
                                    {props.model.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

        </div>
    )
}

export default LotOptionsMenu;