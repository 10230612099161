import React, {useState, useEffect} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import CloseIcon from '@material-ui/icons/Close';
import {ArrowForward, ArrowBack} from "@material-ui/icons";
import Slide from '@material-ui/core/Slide';
import {Dialog} from "@material-ui/core";
import {TransitionProps} from '@material-ui/core/transitions';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type BlackLineModalProps = {
    project_name: string
    optionKey: string
    closeFunction: () => void;
    open: boolean
}

function BlackLineModal(props:BlackLineModalProps) {
    // Does a timed slideshow make sense?
    // TODO Look into timing the move, as well as transition/animations
    const [open, setOpen] = React.useState(props.open);
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <div>
                <ArrowBack onClick={(e) => setPageNumber((pageNumber > 1)? pageNumber - 1 : pageNumber)}/>
                <CloseIcon onClick={props.closeFunction}/>
                <ArrowForward onClick={(e) => setPageNumber((pageNumber < numPages)? pageNumber + 1 : pageNumber)}/>
                <Document
                    file={"https://s3.us-west-1.amazonaws.com/assets.siteplan.xyz/"+ props.project_name + "/black-lines/" + props.optionKey + '.pdf'}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber}/>
                </Document>
            </div>
        </Dialog>
    )
}

export default BlackLineModal;