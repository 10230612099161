import React, {useEffect, useState} from 'react';
import {Grid, makeStyles} from "@material-ui/core";

type lotViewerProps = {
    lotElement: HTMLElement | undefined
    lotNum: number
    lotDimensions: string
}

const useStyles = makeStyles((theme) => ({
    lotViewer: {
        height: "20em",
        width: "30em"
    }
}));


function renderLotElement(lotElement: HTMLElement | undefined, classes) {
    if (lotElement) {
        // FIXME This is probably not a good way to do this. I am opening the site up to possible XSS attack?
        return (
            // @ts-ignore
            <svg className={classes.lotViewer} id="lotViewer" dangerouslySetInnerHTML={{__html: lotElement.outerHTML}}/>
        )
    }
}

function transformLotElement(polygonId) {
    const poly = document.querySelector('#' + polygonId);
    if (poly !== null) {
        // @ts-ignore
        console.log(poly.getBBox())
        // @ts-ignore
        let bbox = poly.getBBox()
        poly.setAttribute("transform", "translate(" + ( bbox["x"]* -1)
            + " " + ( bbox["y"]* -1) + ")" );
    }
    // FIXME If poly is null, what are we meant to do here?
}

const LotViewer = (props:lotViewerProps) => {
    let [loading, setLoading] = useState(true);
    const classes = useStyles()

    useEffect(() => {
        if (loading) {
            transformLotElement(props.lotElement?.id)
        }
    }, [props, loading, setLoading])

    return (
        <Grid container direction="column">
            <Grid item>
                {renderLotElement(props.lotElement, classes)}
            </Grid>
            <Grid item>
                {props.lotNum}
            </Grid>
            <Grid item>
                DIMENSIONS
            </Grid>
        </Grid>
    )
}

export default LotViewer;